<template>
  <LazyDrawer position="right" :is-open="loginTray" :hide-scroll="false">
    <template #header>
      <Stack tag="div" align="start" justify="end">
        <button @click="setLoginTray">
          <span class="sr-only">Close login tray</span>
          <Icon name="plus-thick" :size="16" class="rotate-45" />
        </button>
      </Stack>

      <Heading tag="h3" size="sm" class="mt-xs">Sign in</Heading>
    </template>

    <aside class="pt-md px-sm">
      <Stack tag="div" direction="col">
        <Banner v-if="currentError" @dismiss="clearError" variant="error" data-test-id="trayCredentialsError">
          {{ currentError.message }}
        </Banner>

        <Input
          id="email"
          data-test-id="tray-email"
          size="md"
          :model-value="email"
          label="Email Address"
          data-validation-rule="email"
          @update:input-value="email = String($event)"
          @blur="vee.validateSingleField($event)"
          :error="vee.fieldHasError('email').value"
          :message-text="vee.fieldError('email').value"
        />

        <Input
          id="password"
          data-test-id="tray-password"
          size="md"
          :model-value="password"
          label="Password"
          is-password
          link-text="Forgotten password?"
          link-test-id="loginTrayForgottenPassword"
          link-href="/forgotten-password"
          link-class="text-mkm-blue-light text-sm"
          :link-tag="NuxtLink"
          @link:click="setLoginTray"
          @update:input-value="password = String($event)"
          data-validation-rule="password"
          @blur="vee.validateSingleField($event)"
          :error="vee.fieldHasError('password').value"
          :message-text="vee.fieldError('password').value"
        />

        <div class="w-full">
          <Button
            :disabled="disabledLogin"
            data-test-id="tray-signInButton"
            data-fs="tray-signInButton"
            size="lg"
            variant="secondary"
            @click="onSubmit"
            class="min-w-full justify-center mb-xs"
          >
            SIGN IN
            <Spinner v-show="isLoggingIn" />
          </Button>

          <NuxtLink :to="ROUTES.CREATE_ACCOUNT" class="w-full block" @click="setLoginTray">
            <Text tag="span" size="sm" align="center" class="text-mkm-blue-light w-full block">
              Don’t have an account? Create one now
            </Text>
          </NuxtLink>
        </div>
      </Stack>
    </aside>

    <template #footer>
      <Stack align="center" justify="between" class="w-full">
        <Button variant="neutral" size="md" class="!w-full" @click="setLoginTray" :disabled="isLoggingIn">
          Cancel
          <span class="sr-only">Login Tray</span>
        </Button>
      </Stack>
    </template>
  </LazyDrawer>
</template>

<script setup lang="ts">
import { ROUTES } from "@/helpers/routes/routes";

const { loginTray, setLoginTray } = useUIState();
const vee = useVeeValidate();
const NuxtLink = resolveComponent("NuxtLink");

const { email, password, isLoggingIn, currentError, handleLogin, clearError, resetForm } = useLoginHandler();

const disabledLogin = computed(
  () => vee.fieldHasError("email").value || vee.fieldHasError("password").value || isLoggingIn.value,
);

const onSubmit = async () => {
  const { success } = await handleLogin(false);

  if (success) {
    resetForm();
    setLoginTray();
  }
};
</script>
